var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      staticClass: "widget-config-wrapper",
      attrs: { accordion: "" },
      model: {
        value: _vm.activeIndex,
        callback: function ($$v) {
          _vm.activeIndex = $$v
        },
        expression: "activeIndex",
      },
    },
    [
      _c(
        "el-collapse-item",
        {
          staticClass: "widget-config-item",
          attrs: {
            title: _vm.$t("lang_attribute_configuration"),
            name: "prop",
          },
        },
        [
          _vm.activedWidget && _vm.activedWidget.compType
            ? _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-width": "100px",
                    "label-suffix": "：",
                  },
                },
                [
                  _vm.widgetConfig
                    ? _c(_vm.widgetConfig, {
                        tag: "component",
                        attrs: { widget: _vm.activedWidget },
                        on: {
                          "update:widget": function ($event) {
                            _vm.activedWidget = $event
                          },
                          change: _vm.changeWidgetConfig,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("span", { staticClass: "unconfigable-text" }, [
                _vm._v(_vm._s(_vm.$t("lang_qxszygbdx"))),
              ]),
        ],
        1
      ),
      _c(
        "el-collapse-item",
        {
          staticClass: "widget-config-item",
          attrs: {
            title: _vm.$t("lang_data_source_onfiguration"),
            name: "source",
          },
        },
        [
          _vm._dataSourceConfigable
            ? _c("ele-data-source-config", {
                on: { change: _vm.changeDataSourceConfig },
              })
            : _c("span", { staticClass: "unconfigable-text" }, [
                _vm._v(_vm._s(_vm.$t("lang_qxxzygkypzsjydbdx"))),
              ]),
        ],
        1
      ),
      _c(
        "el-collapse-item",
        {
          staticClass: "widget-config-item",
          attrs: {
            title: _vm.$t("lang_default_value_configuration"),
            name: "default",
          },
        },
        [
          _vm._defaultValueConfigable
            ? [
                _c("ele-default-value-config", {
                  attrs: { widget: _vm.activedWidget },
                  on: { change: _vm.changeDefaultDataConfig },
                }),
              ]
            : [
                _c("span", { staticClass: "unconfigable-text" }, [
                  _vm._v(_vm._s(_vm.$t("lang_qxxzygkypzmrzdbdx"))),
                ]),
              ],
        ],
        2
      ),
      _c(
        "el-collapse-item",
        {
          staticClass: "widget-config-item",
          attrs: {
            title: _vm.$t("lang_trigger_configuration"),
            name: "trigger",
          },
        },
        [
          _vm._triggerConfigable
            ? _c("ele-trigger", { on: { change: _vm.changeTrigger } })
            : _c("span", { staticClass: "unconfigable-text" }, [
                _vm._v(_vm._s(_vm.$t("lang_qxxzygkypzcfqdbdx"))),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }