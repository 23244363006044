








import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "ComIcon",
})
export default class extends Vue {
  @Prop() private code!: string;
  @Prop() private text!: string;
  get iconFont() {
    return "#icon-" + this.code;
  }
}
