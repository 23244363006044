





















































import { Component, Prop, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { saveFormConfigsData } from '@/api/form-design/FormApi';
import StoreIndex from '@/store/StoreIndex';
import { buildFormColumn, getFormConfig, getFormWidgetTree } from '../utils';
@Component({
  name: 'FormColumnConfig',
  components: {}
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;

  @InjectReactive()
  formId: any;

  formWidgetTree: any[] = [];
  columnMap: any = {};

  // 缓存中的表单配置
  get _formConfig() {
    return getFormConfig(this.formId);
  }

  get _formWidgetTree() {
    return getFormWidgetTree(this.formId);
  }

  created() {
    this.initData();
  }

  initData() {
    this.formWidgetTree = SystemUtil.cloneDeep(this._formWidgetTree);
  }

  /**
   * 选中或取消列时
   */
  changeColumnChecked(val, widget) {}

  /*
   *@description: 保存表单
   *@author: gongcaixia
   *@date: 2021-05-18 10:34:09
   */
  saveColumnConfig() {
    let columnMap = this.getFormColumnKey(this.formWidgetTree);
    buildFormColumn(this._formConfig.container.components, columnMap);
    saveFormConfigsData(this._formConfig).then((res: Record<string, any>) => {
      if (res.code == '1') {
        StoreIndex.commit('SET_FORM_CONFIG', this._formConfig);
        this.$emit('success');
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
      this.$emit('update:visible', false);
    });
  }

  /**
   * 获取配置为列的表单选项
   */
  getFormColumnKey(widgetTree) {
    let result = {};
    widgetTree.forEach(item => {
      if (item.display) {
        result[item.compCode] = true;
      }
      if (item.children) {
        result = Object.assign(result, this.getFormColumnKey(item.children));
      }
    });
    return result;
  }

  closeDialog() {
    this.$emit('update:visible', false);
  }

  // 监听缓存中的表单配置
  @Watch('_formWidgetTree')
  onFormConfigWatcher(value: any) {
    this.initData();
  }
}
