









































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import EleText from '../elements/ele-text.vue';
import EleDialogList from '../elements/ele-dialog-list.vue';
import EleEditor from '../elements/ele-editor.vue';
import ElerichTextEditor from '../elements/ele-richTextEditor.vue';
import EleTasks from '../elements/ele-tasks.vue';
import EleHtml from '../elements/ele-html.vue';
import EleDivider from '../elements/ele-divider.vue';
import EleImage from '../elements/ele-image.vue';
import EleUpload from '../elements/ele-upload.vue';
import EleOccupy from '../elements/ele-occupy.vue';
import EleNormalList from '../elements/ele-normalList.vue';
import EleCarousel from '../elements/ele-carousel.vue';
import EleApplication from '../elements/ele-application.vue';

import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';

//属性
interface Properties {
  showLabel?: string;
  labelWidth?: string;
}
@Component({
  name: 'DesignItem',
  components: {
    draggable,
    EleText,
    EleDialogList,
    EleEditor,
    EleHtml,
    EleDivider,
    EleImage,
    EleOccupy,
    EleUpload,
    EleCarousel,
    EleNormalList,
    EleApplication,
    ElerichTextEditor,
    EleTasks
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return SystemUtil.cloneDeep(WidgetData);
    }
  })
  widget!: WidgetModel;
  get _lang() {
    return this.$store.state.home.lang;
  }
  @Prop() value!: any;
  copy(widget) {
    this.$emit('copy', widget);
  }
  remove(widget) {
    this.$emit('remove', widget);
  }
  select(widget) {
    this.$emit('select', widget);
  }
  showLabelFn(value, compType, name) {
    if (['normalList', 'application', 'divider'].includes(compType)) return '';
    if (value) return this.$t(name);
    return '';
  }

  getLabelWidth(properties: Properties, compType: string): string {
    if (['normalList', 'application', 'divider'].includes(compType)) return '0px';
    if (properties.showLabel) return `${properties.labelWidth}px`;
    return '0px';
  }

  mounted() {}
}
