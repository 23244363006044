var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "trigger-wrapper" },
      [
        _c(
          "div",
          { staticClass: "trigger-btn-wrapper" },
          [
            _c(
              "el-button",
              {
                staticClass: "trigger-btn",
                attrs: { type: "primary" },
                on: { click: _vm.addAction },
              },
              [_vm._v(_vm._s(_vm.$t("lang_new_trigger")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "trigger-list" },
          _vm._l(_vm.actionList, function (item, index) {
            return _c("div", { key: item.type, staticClass: "trigger-item" }, [
              _c("span", { staticClass: "trigger-item-name" }, [
                _vm._v(_vm._s(item.title || item.name)),
              ]),
              _c("span", { staticClass: "trigger-item-btn" }, [
                _c("i", {
                  staticClass: "el-icon-edit",
                  on: {
                    click: function ($event) {
                      return _vm.editAction(item)
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeAction(index)
                    },
                  },
                }),
              ]),
            ])
          }),
          0
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.$t("lang_edit_trigger"),
              visible: _vm.actionDialogFlag,
              "close-on-click-modal": false,
              width: "64%",
              top: "10vh",
              "append-to-body": "",
            },
            on: { close: _vm.closeActionDialog },
          },
          [
            _c(
              "el-form",
              {
                ref: "triggerFormRef",
                staticClass: "trigger-form",
                attrs: { "label-width": "120px" },
              },
              [
                _c("el-divider", { staticClass: "cus-divider" }, [
                  _vm._v(_vm._s(_vm.$t("lang_essential_information"))),
                ]),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8, offset: 0 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "trigger-form-item",
                            attrs: {
                              label: _vm.$t("lang_trigger_name"),
                              prop: "name",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_enter_trigger_name"
                                ),
                              },
                              model: {
                                value: _vm.actionModel.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.actionModel, "title", $$v)
                                },
                                expression: "actionModel.title",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "trigger-form-item",
                            attrs: {
                              label: _vm.$t("lang_trigger_mode"),
                              prop: "type",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "lang_please_select_the_trigger_method"
                                  ),
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.actionModel.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.actionModel, "type", $$v)
                                  },
                                  expression: "actionModel.type",
                                },
                              },
                              _vm._l(_vm._actionTypeList, function (item) {
                                return _c("el-option", {
                                  key: item.type,
                                  attrs: { label: item.name, value: item.type },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "trigger-form-item",
                            attrs: {
                              label: _vm.$t("lang_auto_end_trigger_sequence"),
                              prop: "type",
                            },
                          },
                          [
                            _c("el-input-number", {
                              attrs: {
                                size: "normal",
                                label: "",
                                min: 1,
                                max: 100,
                                step: 1,
                                controls: true,
                                "controls-position": "both",
                              },
                              model: {
                                value: _vm.actionModel.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.actionModel, "sort", $$v)
                                },
                                expression: "actionModel.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-divider", { staticClass: "cus-divider" }, [
                  _vm._v(_vm._s(_vm.$t("lang_configuration_information"))),
                ]),
                _vm.actionModel.type
                  ? _c("ele-trigger-config", {
                      ref: "triggerConfigRef",
                      attrs: {
                        triggerType: _vm.actionModel.type,
                        triggerGroups: _vm.actionModel.triggerGroups,
                      },
                      on: {
                        "update:triggerGroups": function ($event) {
                          return _vm.$set(
                            _vm.actionModel,
                            "triggerGroups",
                            $event
                          )
                        },
                        "update:trigger-groups": function ($event) {
                          return _vm.$set(
                            _vm.actionModel,
                            "triggerGroups",
                            $event
                          )
                        },
                      },
                    })
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "16px",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("lang_please_select_the_trigger_method")
                          )
                        ),
                      ]
                    ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.closeActionDialog } }, [
                  _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.saveActionConfig },
                  },
                  [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }