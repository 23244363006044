























































































































































































































































































































































































































































































import AppApi from '@/api/application/AppApi';
import { Component, Vue, Watch, InjectReactive, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
// import FormConfig from '../config/form-config.vue';//zaa修改 FormConfig为异步 解决 flow/design(流程)页面 报错问题
import FormColumnConfig from '../config/form-column-config.vue';
import FormDrawerItem from './form-drawer-item.vue';
import FormViewer from '../viewer/form-viewer.vue';
// 核心样式
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript';
import { isRow, isTable, isCard, getFormConfig, isTab } from '../utils/index';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { FormData, FormModel } from '@/models/form/FormModel';
import { WidgetData } from '@/models/form/WidgetModel';
import { saveFormConfigsData, saveFormData } from '@/api/form-design/FormApi';
import StoreIndex from '@/store/StoreIndex';

@Component({
  name: 'FormDrawer',
  components: {
    draggable,
    codemirror,
    FormConfig: () => import('../config/form-config.vue'),
    FormViewer,
    FormDrawerItem,
    FormColumnConfig
  }
})
export default class Designer extends Vue {
  formConfig: FormModel = SystemUtil.cloneDeep(FormData);
  formConfDialogVisible: boolean = false;
  previewPageVisible: boolean = false;
  jsonDialogVisible: boolean = false;
  codeMirrorOptions: any = {
    tabSize: 2, // 缩进格式
    theme: 'dracula', // 主题，对应主题库 JS 需要提前引入
    lineNumbers: true, // 显示行号
    line: true,
    styleActiveLine: true, // 高亮选中行
    hintOptions: {
      completeSingle: true // 当匹配只有一项的时候是否自动补全
    }
  };
  tabsAlive: any = '0';

  // 表单列配置弹框
  columnConfDialogVisible: boolean = false;

  @InjectReactive()
  formId: any;

  @InjectReactive()
  linkId: any;

  @Prop({ default: '0' })
  formStatus!: string;

  get _formConfigs() {
    return this.$store.getters.formConfigs;
  }

  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  created() {
    this.formConfig = getFormConfig(this.formId);
  }

  buildWidgetDefaultProperties(addWidget) {
    if (!addWidget.id) {
      addWidget.id = SystemUtil.uuid();
      addWidget.compCode = addWidget.compType + '_' + addWidget.id;
    }
    addWidget.properties.labelWidth = this.formConfig.container.properties.labelWidth || addWidget.properties.labelWidth;
    addWidget.properties.fontColor = this.formConfig.container.properties.fontColor || addWidget.properties.fontColor;
    addWidget.properties.lineColor = this.formConfig.container.properties.borderColor || addWidget.properties.lineColor;
    // 位置
    addWidget.properties.labelPosition = this.formConfig.container.properties.labelPosition || addWidget.properties.labelPosition;
    // 是否展示标签
    addWidget.properties.showLabel = this.formConfig.container.properties.showLabel || addWidget.properties.showLabel;
    // 是否显示边框
    addWidget.properties.hasBorder = this.formConfig.container.properties.hasBorder || addWidget.properties.hasBorder;
    // 是否显示标签边框
    addWidget.properties.cellBorder = this.formConfig.container.properties.cellBorder || addWidget.properties.cellBorder;
  }

  // 添加组件到顶级节点上
  handleAddWidget(obj, components) {
    let newIndex = obj.newIndex;
    let addWidget = SystemUtil.cloneDeep(components[newIndex]);
    addWidget.compName = this.$t(addWidget.compName);
    this.buildWidgetDefaultProperties(addWidget);
    components.splice(newIndex, 1, addWidget);
    StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
    this.$emit('change', this.formConfig);
    this.handleSelectWidget(addWidget);
  }
  // 添加组件到栅格中
  handleAddWidgetToGrid(obj, components, gridItem) {
    let newIndex = obj.newIndex;
    if (false && this.isGridOrTableNest(obj)) {
      //防止row嵌套
      const newIndex = obj.newIndex;
      components.splice(newIndex, 1);
      this.$message.error(this.$t('lang_Illegal_operation') as any);
      return false;
    } else {
      let addWidget = SystemUtil.cloneDeep(components[newIndex]);
      addWidget.compName = this.$t(addWidget.compName);
      this.buildWidgetDefaultProperties(addWidget);
      addWidget.parentComponents = {
        id: gridItem.id,
        compName: gridItem.compName,
        compCode: gridItem.compCode,
        compType: gridItem.compType
      };
      components.splice(newIndex, 1, addWidget);
      this.$nextTick(() => {
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$emit('change', this.formConfig);

        this.handleSelectWidget(addWidget);
      });
    }
  }

  // 添加组件到页签中
  handleAddWidgetToTab(obj, tabItem) {
    let newIndex = obj.newIndex;
    let addWidget = SystemUtil.cloneDeep(tabItem.properties.components[newIndex]);
    addWidget.compName = this.$t(addWidget.compName);
    if (this.isGridOrTableNest(obj)) {
      //防止row嵌套
      const newIndex = obj.newIndex;
      let oldIndex = obj.oldIndex;
      tabItem.properties.components.splice(newIndex, 1);
      this.formConfig.container.components.splice(oldIndex, 1, addWidget);
      this.$message.error(this.$t('lang_Illegal_operation') as any);
      return false;
    } else {
      this.buildWidgetDefaultProperties(addWidget);
      addWidget.parentComponents = {
        id: tabItem.id,
        compName: tabItem.compName,
        compCode: tabItem.compCode,
        compType: tabItem.compType
      };
      tabItem.properties.components.splice(newIndex, 1, addWidget);
      this.$nextTick(() => {
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$emit('change', this.formConfig);

        this.handleSelectWidget(addWidget);
      });
    }
  }

  // 添加组件到表格中
  handleAddWidgetToTable(obj, tableItem) {
    let newIndex = obj.newIndex;
    let addWidget = SystemUtil.cloneDeep(tableItem.properties.components[newIndex]);
    addWidget.compName = this.$t(addWidget.compName);
    if (this.isGridOrTableNest(obj)) {
      //防止row嵌套
      let oldIndex = obj.oldIndex;
      tableItem.properties.components.splice(newIndex, 1);
      this.formConfig.container.components.splice(oldIndex, 1, addWidget);
      this.$message.error(this.$t('lang_Illegal_operation') as any);
      return false;
    } else {
      this.buildWidgetDefaultProperties(addWidget);
      addWidget.parentComponents = {
        id: tableItem.id,
        compName: tableItem.compName,
        compCode: tableItem.compCode,
        compType: tableItem.compType
      };
      tableItem.properties.components.splice(newIndex, 1, addWidget);
      this.$nextTick(() => {
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$emit('change', this.formConfig);

        this.handleSelectWidget(addWidget);
      });
    }
  }

  //
  handleAddWidgetToCard(obj, cardItem) {
    let newIndex = obj.newIndex;
    if (this.isCardNest(obj)) {
      //防止row嵌套
      const newIndex = obj.newIndex;
      cardItem.properties.components.splice(newIndex, 1);
      this.$message.error(this.$t('lang_Illegal_operation') as any);
      return false;
    } else {
      let addWidget = SystemUtil.cloneDeep(cardItem.properties.components[newIndex]);
      addWidget.compName = this.$t(addWidget.compName);
      this.buildWidgetDefaultProperties(addWidget);
      addWidget.parentComponents = {
        id: cardItem.id,
        compName: cardItem.compName,
        compCode: cardItem.compCode,
        compType: cardItem.compType
      };
      cardItem.properties.components.splice(newIndex, 1, addWidget);
      this.$nextTick(() => {
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$emit('change', this.formConfig);

        this.handleSelectWidget(addWidget);
      });
    }
  }

  // 判断是否是card嵌套
  isCardNest(obj) {
    if (obj.to.className.indexOf('design-item-card-dragger') > -1) {
      // 如果是从左侧控件栏中 拖拽 栅格或表格到栅格中
      if (obj.item.attributes['attr-widget-type']) {
        if (obj.item.attributes['attr-widget-type'].value == 'card') {
          return true;
        }
      }
      // 如果是从当前设计的表单中拖拽某 栅格或表格 到栅格中
      if (obj.item.className.indexOf('design-item-card') != -1) {
        return true;
      }
    }
    return false;
  }

  // 判断是否是grid或者table嵌套
  isGridOrTableNest(obj) {
    if (obj.to.className.indexOf('design-item-grid-dragger') > -1) {
      // 如果是从左侧控件栏中 拖拽 栅格或表格到栅格中
      if (obj.item.attributes['attr-widget-type']) {
        if (obj.item.attributes['attr-widget-type'].value == 'row' || obj.item.attributes['attr-widget-type'].value == 'table') {
          return true;
        }
      }
      // 如果是从当前设计的表单中拖拽某 栅格或表格 到栅格中
      if (obj.item.className.indexOf('design-item-grid') != -1 || obj.item.className.indexOf('design-item-table') != -1) {
        return true;
      }
    }
    if (obj.to.className.indexOf('design-item-table-dragger') > -1) {
      // 如果是从左侧控件栏中 拖拽 栅格或表格到表格中
      if (obj.item.attributes['attr-widget-type']) {
        if (obj.item.attributes['attr-widget-type'].value == 'row' || obj.item.attributes['attr-widget-type'].value == 'table') {
          // 如果是向表格里插入栅格 或 表格
          return true;
        }
      }
      // 如果是从当前设计的表单中拖拽某 栅格或表格 到表格中
      if (obj.item.className.indexOf('design-item-grid') != -1 || obj.item.className.indexOf('design-item-table') != -1) {
        return true;
      }
    }
    return false;
  }

  handleCopyWidget(copyWidget: any, index: number, components: any[]) {
    let widgetClone = SystemUtil.cloneDeep(copyWidget);
    if (isRow(widgetClone)) {
      //栅格布局组件，需要复制布局组件以及下面的组件
      widgetClone = this.handleCopyGridWidget(widgetClone);
    } else if (isTable(widgetClone)) {
      widgetClone = this.handleCopyTableWidget(widgetClone);
    } else if (isCard(widgetClone)) {
      widgetClone = this.handleCopyCardWidget(widgetClone);
    } else if (isTab(widgetClone)) {
      // 如果是Tab签
      widgetClone = this.handleCopyTabWidget(widgetClone);
    } else {
      widgetClone = this.handleCopySimpleWidget(widgetClone);
    }
    components?.splice(index + 1, 0, widgetClone);
    StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
    this.$emit('change', this.formConfig);

    this.handleSelectWidget(widgetClone);
  }

  handleCopyTabWidget(gridItem) {
    //1.修改父组件id
    let result = this.handleCopySimpleWidget(gridItem);
    //2.递归遍历子组件 修改所有子组件id
    // ！！！！注意：该函数存在循环调用风险 请注意使用！！！
    result.properties.components.map(item => {
      if (isRow(item)) {
        //子组件是 栅格
        item = this.handleCopyGridWidget(item);
      } else if (isTable(item)) {
        // 子组件是Tbale
        item = this.handleCopyTableWidget(item);
      } else if (isCard(item)) {
        // 子组件是Card
        item = this.handleCopyCardWidget(item);
      } else {
        // 其他非布局组件
        item = this.handleCopySimpleWidget(item);
      }
    });
    return result;
  }

  handleCopyGridWidget(gridItem) {
    gridItem = this.handleCopySimpleWidget(gridItem);
    gridItem.properties.gridColumns.map((columnItem, index) => {
      let itemList: Record<string, any>[] = [];
      columnItem.components.map(item => {
        let columnChild = {};
        if (isTab(item)) {
          // 如果栅格组件里嵌套 页签（tab）组件
          columnChild = this.handleCopyTabWidget(item); //handleCopyTabWidget使用时可能有循环调用风险
        } else {
          columnChild = this.handleCopySimpleWidget(item);
        }
        itemList.push(columnChild);
      });
      columnItem.components = itemList;
    });
    return gridItem;
  }

  handleCopyTableWidget(tableItem) {
    tableItem = this.handleCopySimpleWidget(tableItem);
    let itemList: Record<string, any>[] = [];
    tableItem.properties.components.map(item => {
      let tableCell = this.handleCopySimpleWidget(item);
      itemList.push(tableCell);
    });
    tableItem.properties.components = itemList;
    return tableItem;
  }

  handleCopyCardWidget(cardItem) {
    cardItem = this.handleCopySimpleWidget(cardItem);
    let itemList: Record<string, any>[] = [];
    cardItem.properties.components.map((item, i) => {
      let cardChild = SystemUtil.cloneDeep(item);
      if (isRow(cardChild)) {
        //栅格布局组件，需要复制布局组件以及下面的组件
        cardChild = this.handleCopyGridWidget(cardChild);
      } else if (isTable(cardChild)) {
        cardChild = this.handleCopyTableWidget(cardChild);
      } else {
        cardChild = this.handleCopySimpleWidget(cardChild);
      }
      itemList.push(cardChild);
    });
    cardItem.properties.components = itemList;
    return cardItem;
  }

  handleCopySimpleWidget(simpleWidget) {
    simpleWidget.id = SystemUtil.uuid();
    simpleWidget.compCode = simpleWidget.compType + '_' + simpleWidget.id;
    simpleWidget.compName = this.$t(simpleWidget.compName) + '_copy';
    return simpleWidget;
  }

  handleDeleteWidget(origin: Record<string, any>, index: number, components: any[]) {
    components.splice(index, 1);
    StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
    this.$emit('change', this.formConfig);
    this.handleRemoveWidget();
  }
  // 移除某控件
  handleRemoveWidget() {
    this.$store.commit('SET_ACTIVED_WIDGET', SystemUtil.cloneDeep(WidgetData));
  }

  /**
   * 改变组件排序
   */
  handleSortWidget() {
    StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
    this.$emit('change', this.formConfig);
  }

  handleSelectWidget(obj: Record<string, any>) {
    this.$store.commit('SET_ACTIVED_WIDGET', obj);
  }

  // 配置表单基础信息
  handleConfigForm() {
    this.formConfDialogVisible = true;
  }

  // 配置表单的列信息（用于表格展示）
  handleConfigColumn() {
    this.columnConfDialogVisible = true;
  }

  /*
   *@description: 保存表单
   *@author: gongcaixia
   *@date: 2021-05-18 10:34:09
   */
  handleSaveFormConfig() {
    if (!this.formConfig.name) {
      return;
    }
    // let formConfig = SystemUtil.cloneDeep(this.formConfig);

    let formConfig = getFormConfig(this.formId);
    saveFormConfigsData(formConfig).then((res: Record<string, any>) => {
      if (res.code == '1') {
        if (!res.data.container.components) {
          res.data.container.components = [];
        }
        this.formConfig = res.data;
        StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
        this.$message.success(res.message);
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /*
   *@description: 发布表单
   *@author: gongcaixia
   *@date: 2021-05-18 10:34:09
   */
  handleReleaseForm() {
    if (!this.linkId) {
      return;
    }
    this.$confirm(this.$t('lang_are_you_sure_to_release_it') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      AppApi.publishAppLink(this.linkId).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 预览表单
  handlePreviewForm() {
    if (!this.formConfig.name) {
      return;
    }
    let formConfig = getFormConfig(this.formId);
    if (formConfig.status == '0') {
      saveFormConfigsData(formConfig).then((res: Record<string, any>) => {
        if (res.code == '1') {
          if (!res.data.container.components) {
            res.data.components = [];
          }
          this.formConfig = res.data;
          StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
          this.previewPageVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    } else {
      this.previewPageVisible = true;
    }
  }
  /**
   * description  提交
   * @param {}
   * @returns {}
   */
  handleSaveFormData() {
    (this.$refs.formViewerRef as any).getFormDataForSave().then(result => {
      saveFormData(result).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
        }
      });
    });
  }

  // 查看表单json
  handleViewFormJson() {
    this.jsonDialogVisible = true;
  }

  handleClearForm() {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string).then(() => {
      this.formConfig.container.components = [];
      StoreIndex.commit('SET_FORM_CONFIG', this.formConfig);
      this.$emit('change', this.formConfig);
    });
  }
  handleHelp() {
    this.$message('帮助！');
  }
  // @Watch('_formConfigs', { deep: true })
  // onFormConfigsWatcher(value: FormModel) {
  //   // this.formConfig = getFormConfig(this.formId);
  //   // let { ...others } = getFormConfig(this.formId);
  //   // this.formConfig = { ...others, container: this.formConfig.container };
  // }

  get code() {
    return JSON.stringify(this.formConfig, null, 4);
  }
}
