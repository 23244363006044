var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text" }, [
    _c("svg", { staticClass: "icon", attrs: { "aria-hidden": "true" } }, [
      _c("use", { attrs: { "xlink:href": _vm.iconFont } }),
    ]),
    _vm._v(" " + _vm._s(_vm.text) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }