var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.widget.id
    ? _c(
        "div",
        {
          staticClass: "drawing-item",
          attrs: { width: _vm.widget.properties.width },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.showLabelFn(
                  _vm.widget.properties.showLabel,
                  _vm.widget.compType,
                  _vm.widget.compName
                ),
                "label-width": _vm.getLabelWidth(
                  _vm.widget.properties,
                  _vm.widget.compType
                ),
                required: _vm.widget.properties.required,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.select(_vm.widget)
                },
              },
            },
            [
              _vm.widget.compType == "input"
                ? [_c("el-input", { attrs: { value: _vm.value } })]
                : _vm._e(),
              _vm.widget.compType == "textarea"
                ? [
                    _c("el-input", {
                      attrs: { value: _vm.value, type: "textarea" },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "text"
                ? [_c("ele-text", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "inputNumber"
                ? [
                    _c("el-input-number", {
                      attrs: {
                        value: _vm.value,
                        min: _vm.widget.properties.min,
                        max: _vm.widget.properties.max,
                        step: _vm.widget.properties.step,
                        precision: _vm.widget.properties.precision,
                        "controls-position":
                          _vm.widget.properties.controlsPosition,
                        disabled: _vm.widget.properties.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "date"
                ? [
                    _c("el-date-picker", {
                      attrs: {
                        value: _vm.value,
                        type: _vm.widget.properties.type,
                        placeholder: _vm.$t(_vm.widget.properties.placeholder),
                        format: _vm.widget.properties.format,
                        "value-format": "timestamp",
                        disabled: _vm.widget.properties.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "time"
                ? [
                    _c("el-time-picker", {
                      attrs: {
                        value: _vm.value,
                        placeholder: _vm.$t(_vm.widget.properties.placeholder),
                        format: _vm.widget.properties.format,
                        "value-format": "HH-mm-ss",
                        disabled: _vm.widget.properties.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "select"
                ? [
                    _c("el-select", {
                      attrs: {
                        value: _vm.value,
                        placeholder: _vm.$t(_vm.widget.properties.placeholder),
                        disabled: _vm.widget.properties.disabled,
                        filterable: _vm.widget.properties.filterable,
                        multiple: _vm.widget.properties.multiple,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "radio"
                ? [
                    _vm.widget.properties.optionType === "button"
                      ? [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                type: "vertical",
                                value: _vm.value,
                                vertical: _vm.widget.properties.vertical,
                                disabled: _vm.widget.properties.disabled,
                                size: _vm.widget.properties.size,
                              },
                            },
                            _vm._l(_vm.widget.dataSource, function (item) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: item.value,
                                  attrs: {
                                    label: item.value,
                                    border: _vm.widget.properties.border,
                                  },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ]
                      : [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                value: _vm.value,
                                vertical: _vm.widget.properties.vertical,
                                disabled: _vm.widget.properties.disabled,
                                size: _vm.widget.properties.size,
                              },
                            },
                            _vm._l(
                              _vm.widget.dataSource.dataList,
                              function (item) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: item.value,
                                    attrs: {
                                      label: item.value,
                                      border: _vm.widget.properties.border,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                  ]
                : _vm._e(),
              _vm.widget.compType == "checkbox"
                ? [
                    _vm.widget.properties.optionType === "button"
                      ? [
                          _c(
                            "el-checkbox-group",
                            {
                              staticStyle: { "min-height": "37px" },
                              attrs: {
                                value: _vm.value,
                                disabled: _vm.widget.properties.disabled,
                                size: _vm.widget.properties.size,
                              },
                            },
                            _vm._l(
                              _vm.widget.dataSource.dataList,
                              function (item) {
                                return _c(
                                  "el-checkbox-button",
                                  {
                                    key: item.value,
                                    attrs: {
                                      label: item.value,
                                      border: _vm.widget.properties.border,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ]
                      : [
                          _c(
                            "el-checkbox-group",
                            {
                              staticStyle: { "min-height": "37px" },
                              attrs: {
                                value: _vm.value,
                                disabled: _vm.widget.properties.disabled,
                                size: _vm.widget.properties.size,
                              },
                            },
                            _vm._l(
                              _vm.widget.dataSource.dataList,
                              function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.value,
                                    attrs: {
                                      label: item.value,
                                      border: _vm.widget.properties.border,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.label))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                  ]
                : _vm._e(),
              _vm.widget.compType == "cascader"
                ? [
                    _c("el-cascader", {
                      style: { width: _vm.widget.properties.width + "%" },
                      attrs: {
                        value: _vm.value,
                        options: _vm.widget.dataSource,
                        placeholder: _vm.$t(_vm.widget.properties.placeholder),
                        disabled: _vm.widget.properties.disabled,
                        props: {
                          label: "text",
                          value: "id",
                          emitPath: false,
                          multiple: _vm.widget.properties.multiple,
                        },
                        "show-all-levels": _vm.widget.properties.showAllLevels,
                        separator: _vm.widget.properties.separator,
                        filterable: _vm.widget.properties.filterable,
                        size: _vm.widget.properties.size,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "tree"
                ? [
                    _c("el-select", {
                      attrs: {
                        value: _vm.value,
                        placeholder: _vm.$t(_vm.widget.properties.placeholder),
                        disabled: _vm.widget.properties.disabled,
                        filterable: _vm.widget.properties.filterable,
                        multiple: _vm.widget.properties.multiple,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "dialogList"
                ? [
                    _c("ele-dialog-list", {
                      attrs: { widget: _vm.widget },
                      on: {
                        "update:widget": function ($event) {
                          _vm.widget = $event
                        },
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "switch"
                ? [
                    _c("el-switch", {
                      attrs: {
                        value: _vm.value,
                        "active-color": _vm.widget.properties.activeColor,
                        "inactive-color": _vm.widget.properties.inactiveColor,
                        "active-value": _vm.widget.properties.activeValue,
                        "inactive-value": _vm.widget.properties.inactiveValue,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "slider"
                ? [
                    _c("el-slider", {
                      attrs: {
                        value: _vm.value,
                        step: _vm.widget.properties.step,
                        "show-stops": _vm.widget.properties.showStops,
                        min: _vm.widget.properties.min,
                        max: _vm.widget.properties.max,
                        disabled: _vm.widget.properties.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "rate"
                ? [
                    _c("el-rate", {
                      attrs: {
                        value: _vm.value,
                        "allow-half": _vm.widget.properties.allowHalf,
                        "show-score": _vm.widget.properties.showScore,
                        max: _vm.widget.properties.max,
                        disabled: _vm.widget.properties.disabled,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "editor"
                ? [
                    _c("ele-editor", {
                      attrs: {
                        value: _vm.value,
                        properties: _vm.widget.properties,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "richTextEditor"
                ? [
                    _c("ElerichTextEditor", {
                      attrs: {
                        value: _vm.value,
                        properties: _vm.widget.properties,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "colorPicker"
                ? [
                    _c("el-color-picker", {
                      attrs: {
                        value: _vm.value,
                        size: _vm.widget.properties.size,
                        "show-alpha": _vm.widget.properties.showAlpha,
                        disabled: _vm.widget.properties.disabled,
                        predefine: _vm.widget.properties.predefine,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "upload"
                ? [
                    _c("ele-upload", {
                      style: { width: _vm.widget.properties.width + "%" },
                      attrs: {
                        action: _vm.widget.properties.action,
                        accept: _vm.widget.properties.accepts,
                        multiple: _vm.widget.properties.multiple,
                        disabled: _vm.widget.properties.disabled,
                        buttonText: _vm.widget.properties.buttonText,
                        limit: _vm.widget.properties.limit,
                        "show-file-list": _vm.widget.properties.showFileList,
                        "list-type": _vm.widget.properties.listType,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "image"
                ? [_c("ele-image", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "occupy"
                ? [_c("ele-occupy", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "normalList"
                ? [_c("ele-normalList", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "carousel"
                ? [_c("ele-carousel", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "application"
                ? [_c("ele-application", { attrs: { widget: _vm.widget } })]
                : _vm._e(),
              _vm.widget.compType == "button"
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.widget.properties.type,
                          disabled: _vm.widget.properties.disabled,
                          size: _vm.widget.properties.size,
                          plain: _vm.widget.properties.plain,
                          round: _vm.widget.properties.round,
                          circle: _vm.widget.properties.circle,
                          loading: _vm.widget.properties.loading,
                          icon: _vm.widget.properties.icon,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(_vm.widget.compName)) + " ")]
                    ),
                  ]
                : _vm._e(),
              _vm.widget.compType == "divider"
                ? [
                    _c("ele-divider", {
                      attrs: { widget: _vm.widget, value: _vm.widget.compName },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "alert"
                ? [
                    _c("el-alert", {
                      attrs: {
                        title: _vm.widget.properties.title,
                        type: _vm.widget.properties.type,
                        effect: _vm.widget.properties.effect,
                        closable: _vm.widget.properties.closable,
                        center: _vm.widget.properties.center,
                        "show-icon": _vm.widget.properties.showIcon,
                        "close-text": _vm.widget.properties.closeText,
                        description: _vm.widget.properties.description,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "link"
                ? [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: _vm.widget.properties.type,
                          href: _vm.widget.properties.href,
                          disabled: _vm.widget.properties.disabled,
                          target: _vm.widget.properties.target,
                        },
                      },
                      [_vm._v(_vm._s(_vm.widget.properties.text))]
                    ),
                  ]
                : _vm._e(),
              _vm.widget.compType == "html"
                ? [
                    _c("ele-html", {
                      attrs: { properties: _vm.widget.properties },
                    }),
                  ]
                : _vm._e(),
              _vm.widget.compType == "tasks"
                ? [
                    _c("ele-tasks", {
                      attrs: { properties: _vm.widget.properties },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "design-tools-copy",
              attrs: { title: _vm.$t("lang_copy") },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.copy(_vm.widget)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-copy-document" })]
          ),
          _c(
            "span",
            {
              staticClass: "design-tools-delete",
              attrs: { title: _vm.$t("lang_delete") },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.remove(_vm.widget)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-delete" })]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }