









































































import { Vue, Component, InjectReactive, Watch } from 'vue-property-decorator';
import { WidgetActionModel, WidgetActionData } from '@/models/form/WidgetActionModel';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import EleTriggerConfig from './ele-trigger-config.vue';
@Component({
  name: 'EleTrigger',
  components: { EleTriggerConfig }
})
export default class extends Vue {
  actionList: WidgetActionModel[] = [];
  actionDialogFlag: boolean = false;
  actionModel: WidgetActionModel = SystemUtil.cloneDeep(WidgetActionData);
  widgetDefaultValue: any = null;
  currentEffectWidget: WidgetModel = SystemUtil.cloneDeep(WidgetData);
  currentEffectWidgetIndex: number = -1;

  showEffectWidgetDataSourceDialog: boolean = false;

  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  get _actionTypeList() {
    let actions: any[] = [];
    if (this._activedWidget && this._activedWidget.compCode) {
      let actionStruct = this.$store.getters.actionStruct;
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = this._activedWidget.compType;
      let actionTypeKeys = widgetConfigMapping.find(item => item.type == compType).actionTypes;
      if (actionTypeKeys) {
        actionTypeKeys.forEach(typeKey => {
          if (actionStruct[typeKey]) {
            actions.push(actionStruct[typeKey]);
          }
        });
      }
    }
    return actions;
  }

  created() {
    this.initData();
  }

  initData() {
    this.actionList = this._activedWidget.actions || [];
  }
  // 添加触发器
  addAction() {
    this.actionModel = SystemUtil.cloneDeep(WidgetActionData);
    this.actionDialogFlag = true;
  }

  // 编辑触发器
  editAction(action: WidgetActionModel) {
    if (!action.id) {
      action.id = SystemUtil.uuid();
    }
    this.actionModel = SystemUtil.cloneDeep(action);
    this.actionDialogFlag = true;
  }

  // 删除触发器
  removeAction(index) {
    this.actionList.splice(index, 1);
    this.actionModel = SystemUtil.cloneDeep(WidgetActionData);
    // 修改触发器删除时 不改变传值
    let widgetClone = SystemUtil.cloneDeep(this._activedWidget);
    this.$store.commit('SET_ACTIVED_WIDGET', widgetClone);
    this.$emit('change', widgetClone);
  }

  // 关闭触发器弹出框
  closeActionDialog() {
    this.actionDialogFlag = false;
  }

  /**
   * description  配置数据源弹框 确定
   * @param {}
   * @returns {}
   */
  async saveActionConfig() {
    if (this.$refs.triggerConfigRef) {
      let result = (this.$refs.triggerConfigRef as any).validateAllGroup();
      result
        .then(v => {
          if (v) {
            if (this.actionModel.type) {
              let curSelectActionType = this._actionTypeList.find(item => item.type == this.actionModel.type);
              let actionConfig: WidgetActionModel = {
                type: curSelectActionType.type,
                name: curSelectActionType.name,
                title: this.actionModel.title,
                groupType: curSelectActionType.groupType,
                sort: this.actionModel.sort,
                triggerGroups: (this.$refs.triggerConfigRef as any).getTriggerGroupsResult()
              };
              if (!this.actionModel.id) {
                actionConfig.id = SystemUtil.uuid();
                this.actionModel.id = actionConfig.id;
              } else {
                actionConfig.id = this.actionModel.id;
              }
              let widgetClone = SystemUtil.cloneDeep(this._activedWidget);
              if (!widgetClone.actions || widgetClone.actions.length == 0) {
                widgetClone.actions = [actionConfig];
                this.actionList.push(actionConfig);
              } else {
                let curActionIndex = widgetClone.actions.findIndex(item => actionConfig.id == item.id);
                widgetClone.actions[curActionIndex] = SystemUtil.cloneDeep(actionConfig);
                this.actionList.splice(curActionIndex, 1, actionConfig);
              }

              this.$store.commit('SET_ACTIVED_WIDGET', widgetClone);
              this.$emit('change', widgetClone);
              this.actionDialogFlag = false;
            }
          }
        })
        .catch(e => {
          this.$message.warning(this.$t('lang_perfect_param_config') as string);
        });
    } else {
      this.$message.warning(this.$t('lang_perfect_param_config') as string);
    }
  }

  @Watch('_activedWidget', { deep: true })
  widgetWatcher(newVal: WidgetModel, oldVal: WidgetModel) {
    if (newVal.id != oldVal.id) {
      this.initData();
    }
  }
}
