var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formId
    ? _c(
        "div",
        { staticClass: "form-drawer-container" },
        [
          _vm.formStatus == "0"
            ? _c(
                "div",
                { staticClass: "form-tools-bar" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-finished", type: "text" },
                      on: { click: _vm.handleSaveFormConfig },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_preservation")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-view",
                        type: "text",
                        disabled: !_vm.formConfig.id,
                        title: _vm.$t("lang_please_save_setting"),
                      },
                      on: { click: _vm.handlePreviewForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_preview")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-s-promotion", type: "text" },
                      on: { click: _vm.handleReleaseForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_release")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-s-tools", type: "text" },
                      on: { click: _vm.handleConfigForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_form_settings")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-s-tools", type: "text" },
                      on: { click: _vm.handleConfigColumn },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lang_form_column_settings")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "delete-btn",
                      attrs: { icon: "el-icon-delete-solid", type: "text" },
                      on: { click: _vm.handleClearForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_empty")) + " ")]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "form-tools-bar" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-view",
                        type: "text",
                        disabled: !_vm.formConfig.id,
                        title: _vm.$t("lang_please_save_setting"),
                      },
                      on: { click: _vm.handlePreviewForm },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang_preview")) + " ")]
                  ),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "form-drawer-wrapper" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { height: "100%", position: "relative" },
                  attrs: {
                    size: _vm.formConfig.container.properties.size,
                    "label-position":
                      _vm.formConfig.container.properties.labelPosition,
                    disabled: _vm.formConfig.container.properties.disabled,
                    "label-width":
                      _vm.formConfig.container.properties.labelWidth + "px",
                  },
                },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "design-content",
                      attrs: { animation: 100, group: "formDragableGroup" },
                      on: {
                        add: function (e) {
                          return _vm.handleAddWidget(
                            e,
                            _vm.formConfig.container.components
                          )
                        },
                        sort: _vm.handleSortWidget,
                        remove: _vm.handleRemoveWidget,
                      },
                      model: {
                        value: _vm.formConfig.container.components,
                        callback: function ($$v) {
                          _vm.$set(_vm.formConfig.container, "components", $$v)
                        },
                        expression: "formConfig.container.components",
                      },
                    },
                    [
                      _vm._l(
                        _vm.formConfig.container.components,
                        function (element, index) {
                          return [
                            element.compType == "card"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: element.id,
                                      staticClass:
                                        "design-item design-item-card",
                                      class: {
                                        "design-item-actived":
                                          _vm._activedWidget.id === element.id,
                                      },
                                      attrs: {
                                        gutter: element.properties.gutter,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleSelectWidget(element)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          staticClass:
                                            "design-item-dragger design-item-card-dragger",
                                          attrs: {
                                            animation: "100",
                                            group: "formDragableGroup",
                                          },
                                          on: {
                                            add: function (e) {
                                              return _vm.handleAddWidgetToCard(
                                                e,
                                                element
                                              )
                                            },
                                            sort: _vm.handleSortWidget,
                                            remove: _vm.handleRemoveWidget,
                                          },
                                          model: {
                                            value:
                                              element.properties.components,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                element.properties,
                                                "components",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "element.properties.components",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            element.properties.components,
                                            function (cardItem, cardIndex) {
                                              return [
                                                cardItem.compType == "row"
                                                  ? _c(
                                                      "el-row",
                                                      {
                                                        key: cardItem.id,
                                                        staticClass:
                                                          "design-item design-item-grid",
                                                        class: {
                                                          "design-item-actived":
                                                            _vm._activedWidget
                                                              .id ===
                                                            cardItem.id,
                                                        },
                                                        attrs: {
                                                          gutter:
                                                            cardItem.properties
                                                              .gutter,
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.handleSelectWidget(
                                                              cardItem
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._l(
                                                          cardItem.properties
                                                            .gridColumns,
                                                          function (
                                                            columnItem,
                                                            columnIndex
                                                          ) {
                                                            return _c(
                                                              "el-col",
                                                              {
                                                                key:
                                                                  "col_" +
                                                                  columnIndex,
                                                                staticClass:
                                                                  "design-item-grid-col",
                                                                attrs: {
                                                                  span: columnItem.span,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "draggable",
                                                                  {
                                                                    staticClass:
                                                                      "design-item-dragger design-item-grid-dragger",
                                                                    attrs: {
                                                                      animation:
                                                                        "100",
                                                                      group:
                                                                        "formDragableGroup",
                                                                    },
                                                                    on: {
                                                                      add: function (
                                                                        e
                                                                      ) {
                                                                        return _vm.handleAddWidgetToGrid(
                                                                          e,
                                                                          columnItem.components,
                                                                          cardItem
                                                                        )
                                                                      },
                                                                      sort: _vm.handleSortWidget,
                                                                      remove:
                                                                        _vm.handleRemoveWidget,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        columnItem.components,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            columnItem,
                                                                            "components",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "columnItem.components",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    columnItem.components,
                                                                    function (
                                                                      widgetItem,
                                                                      widgetIndex
                                                                    ) {
                                                                      return _c(
                                                                        "form-drawer-item",
                                                                        {
                                                                          key:
                                                                            "col" +
                                                                            columnIndex +
                                                                            "_widget" +
                                                                            widgetIndex,
                                                                          staticClass:
                                                                            "design-item",
                                                                          class:
                                                                            {
                                                                              "design-item-actived":
                                                                                _vm
                                                                                  ._activedWidget
                                                                                  .id ===
                                                                                widgetItem.id,
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              widget:
                                                                                widgetItem,
                                                                            },
                                                                          on: {
                                                                            copy: function (
                                                                              widget
                                                                            ) {
                                                                              return _vm.handleCopyWidget(
                                                                                widget,
                                                                                widgetIndex,
                                                                                columnItem.components
                                                                              )
                                                                            },
                                                                            remove:
                                                                              function (
                                                                                widget
                                                                              ) {
                                                                                return _vm.handleDeleteWidget(
                                                                                  widget,
                                                                                  widgetIndex,
                                                                                  columnItem.components
                                                                                )
                                                                              },
                                                                            select:
                                                                              _vm.handleSelectWidget,
                                                                          },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "design-tools-copy",
                                                            attrs: {
                                                              title:
                                                                _vm.$t(
                                                                  "lang_copy"
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.handleCopyWidget(
                                                                  cardItem,
                                                                  cardIndex,
                                                                  element
                                                                    .properties
                                                                    .components
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-copy-document",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "design-tools-delete",
                                                            attrs: {
                                                              title:
                                                                _vm.$t(
                                                                  "lang_delete"
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return (function (
                                                                  widget
                                                                ) {
                                                                  return _vm.handleDeleteWidget(
                                                                    cardItem,
                                                                    cardIndex,
                                                                    element
                                                                      .properties
                                                                      .components
                                                                  )
                                                                })($event)
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-delete",
                                                            }),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : cardItem.compType == "table"
                                                  ? [
                                                      _c(
                                                        "div",
                                                        {
                                                          key: cardItem.id,
                                                          staticClass:
                                                            "design-item design-item-table",
                                                          class: {
                                                            "design-item-actived":
                                                              _vm._activedWidget
                                                                .id ===
                                                              cardItem.id,
                                                          },
                                                          attrs: {
                                                            title: cardItem.id,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.handleSelectWidget(
                                                                cardItem
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          [
                                                            _c(
                                                              "table",
                                                              {
                                                                staticStyle: {
                                                                  "border-color":
                                                                    "#ffffff",
                                                                },
                                                                attrs: {
                                                                  border: "1",
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                  cellspacing:
                                                                    "0",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "tr",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "20%",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    cardItem
                                                                      .properties
                                                                      .components,
                                                                    function (
                                                                      item,
                                                                      thIndex
                                                                    ) {
                                                                      return _c(
                                                                        "th",
                                                                        {
                                                                          key:
                                                                            "table_" +
                                                                            index +
                                                                            "th_" +
                                                                            thIndex,
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "42px",
                                                                              "box-sizing":
                                                                                "border-box",
                                                                              "text-align":
                                                                                "center",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  item.compName
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                                _c(
                                                                  "draggable",
                                                                  {
                                                                    staticClass:
                                                                      "design-item-dragger design-item-table-dragger",
                                                                    attrs: {
                                                                      animation:
                                                                        "100",
                                                                      tag: "tr",
                                                                      group:
                                                                        "formDragableGroup",
                                                                    },
                                                                    on: {
                                                                      add: function (
                                                                        e
                                                                      ) {
                                                                        return _vm.handleAddWidgetToTable(
                                                                          e,
                                                                          cardItem
                                                                        )
                                                                      },
                                                                      sort: _vm.handleSortWidget,
                                                                      remove:
                                                                        _vm.handleRemoveWidget,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        cardItem
                                                                          .properties
                                                                          .components,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            cardItem.properties,
                                                                            "components",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "cardItem.properties.components",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._l(
                                                                      cardItem
                                                                        .properties
                                                                        .components,
                                                                      function (
                                                                        item,
                                                                        thIndex
                                                                      ) {
                                                                        return _c(
                                                                          "td",
                                                                          {
                                                                            key:
                                                                              "table_" +
                                                                              index +
                                                                              "td_" +
                                                                              thIndex,
                                                                            staticClass:
                                                                              "design-item-table-td",
                                                                            staticStyle:
                                                                              {
                                                                                "text-align":
                                                                                  "center",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "form-drawer-item",
                                                                              {
                                                                                staticClass:
                                                                                  "design-item",
                                                                                class:
                                                                                  {
                                                                                    "design-item-actived":
                                                                                      _vm
                                                                                        ._activedWidget
                                                                                        .id ===
                                                                                      item.id,
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    widget:
                                                                                      item,
                                                                                  },
                                                                                on: {
                                                                                  copy: function (
                                                                                    widget
                                                                                  ) {
                                                                                    return _vm.handleCopyWidget(
                                                                                      widget,
                                                                                      thIndex,
                                                                                      cardItem
                                                                                        .properties
                                                                                        .components
                                                                                    )
                                                                                  },
                                                                                  remove:
                                                                                    function (
                                                                                      widget
                                                                                    ) {
                                                                                      return _vm.handleDeleteWidget(
                                                                                        widget,
                                                                                        thIndex,
                                                                                        cardItem
                                                                                          .properties
                                                                                          .components
                                                                                      )
                                                                                    },
                                                                                  select:
                                                                                    _vm.handleSelectWidget,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    cardItem
                                                                      .properties
                                                                      .components
                                                                      .length ==
                                                                    0
                                                                      ? _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "design-item-table-td",
                                                                            staticStyle:
                                                                              {
                                                                                height:
                                                                                  "80px",
                                                                                width:
                                                                                  "100%",
                                                                                color:
                                                                                  "#ccc",
                                                                                "text-align":
                                                                                  "center",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "lang_field_from_left"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          _c(
                                                            "span",
                                                            {
                                                              key:
                                                                "table_copy_" +
                                                                index,
                                                              staticClass:
                                                                "design-tools-copy",
                                                              attrs: {
                                                                title:
                                                                  _vm.$t(
                                                                    "lang_copy"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.handleCopyWidget(
                                                                      cardItem,
                                                                      cardIndex,
                                                                      element
                                                                        .properties
                                                                        .components
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-copy-document",
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              key:
                                                                "table_del_" +
                                                                index,
                                                              staticClass:
                                                                "design-tools-delete",
                                                              attrs: {
                                                                title:
                                                                  _vm.$t(
                                                                    "lang_delete"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.handleDeleteWidget(
                                                                      cardItem,
                                                                      cardIndex,
                                                                      element
                                                                        .properties
                                                                        .components
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  : [
                                                      _c("form-drawer-item", {
                                                        key: cardItem.id,
                                                        staticClass:
                                                          "design-item",
                                                        class: {
                                                          "design-item-actived":
                                                            _vm._activedWidget
                                                              .id ===
                                                            cardItem.id,
                                                        },
                                                        attrs: {
                                                          widget: cardItem,
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.handleSelectWidget,
                                                          copy: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleCopyWidget(
                                                              widget,
                                                              cardIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                          remove: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleDeleteWidget(
                                                              widget,
                                                              cardIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "design-tools-copy",
                                          attrs: { title: _vm.$t("lang_copy") },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleCopyWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "design-tools-delete",
                                          attrs: {
                                            title: _vm.$t("lang_delete"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : element.compType == "row"
                              ? [
                                  _c(
                                    "el-row",
                                    {
                                      key: "grid_" + element.id,
                                      staticClass:
                                        "design-item design-item-grid",
                                      class: {
                                        "design-item-actived":
                                          _vm._activedWidget.id === element.id,
                                      },
                                      attrs: {
                                        gutter: element.properties.gutter,
                                        title: element.id,
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleSelectWidget(element)
                                        },
                                      },
                                    },
                                    [
                                      _vm._l(
                                        element.properties.gridColumns,
                                        function (columnItem, columnIndex) {
                                          return _c(
                                            "el-col",
                                            {
                                              key:
                                                element.id +
                                                "_col_" +
                                                columnIndex,
                                              staticClass:
                                                "design-item-grid-col",
                                              attrs: { span: columnItem.span },
                                            },
                                            [
                                              _c(
                                                "draggable",
                                                {
                                                  staticClass:
                                                    "design-item-dragger design-item-grid-dragger",
                                                  attrs: {
                                                    animation: "100",
                                                    group: "formDragableGroup",
                                                  },
                                                  on: {
                                                    add: function (e) {
                                                      return _vm.handleAddWidgetToGrid(
                                                        e,
                                                        columnItem.components,
                                                        element
                                                      )
                                                    },
                                                    sort: _vm.handleSortWidget,
                                                    remove:
                                                      _vm.handleRemoveWidget,
                                                  },
                                                  model: {
                                                    value:
                                                      columnItem.components,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        columnItem,
                                                        "components",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "columnItem.components",
                                                  },
                                                },
                                                _vm._l(
                                                  columnItem.components,
                                                  function (
                                                    widgetItem,
                                                    widgetIndex
                                                  ) {
                                                    return _c(
                                                      "form-drawer-item",
                                                      {
                                                        key:
                                                          "card_item_" +
                                                          widgetItem.id,
                                                        staticClass:
                                                          "design-item",
                                                        class: {
                                                          "design-item-actived":
                                                            _vm._activedWidget
                                                              .id ===
                                                            widgetItem.id,
                                                        },
                                                        attrs: {
                                                          widget: widgetItem,
                                                        },
                                                        on: {
                                                          copy: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleCopyWidget(
                                                              widget,
                                                              widgetIndex,
                                                              columnItem.components
                                                            )
                                                          },
                                                          remove: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleDeleteWidget(
                                                              widget,
                                                              widgetIndex,
                                                              columnItem.components
                                                            )
                                                          },
                                                          select:
                                                            _vm.handleSelectWidget,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "design-tools-copy",
                                          attrs: { title: _vm.$t("lang_copy") },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleCopyWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "design-tools-delete",
                                          attrs: {
                                            title: _vm.$t("lang_delete"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              : element.compType == "tab"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: "grid_" + element.id,
                                      staticClass:
                                        "design-item design-item-table",
                                      class: {
                                        "design-item-actived":
                                          _vm._activedWidget.id === element.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleSelectWidget(element)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticStyle: {
                                            "border-color": "#ffffff",
                                          },
                                          attrs: {
                                            border: "1",
                                            width: "100%",
                                            height: "100%",
                                            cellspacing: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "tr",
                                            { staticStyle: { height: "20%" } },
                                            _vm._l(
                                              element.properties.components,
                                              function (item) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: item.id,
                                                    staticStyle: {
                                                      height: "42px",
                                                      "box-sizing":
                                                        "border-box",
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(item.compName)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "draggable",
                                            {
                                              staticClass:
                                                "design-item-dragger design-item-table-dragger",
                                              attrs: {
                                                animation: "100",
                                                tag: "tr",
                                                group: "formDragableGroup",
                                              },
                                              on: {
                                                add: function (e) {
                                                  return _vm.handleAddWidgetToTab(
                                                    e,
                                                    element
                                                  )
                                                },
                                                sort: _vm.handleSortWidget,
                                                remove: _vm.handleRemoveWidget,
                                              },
                                              model: {
                                                value:
                                                  element.properties.components,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    element.properties,
                                                    "components",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "element.properties.components",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                element.properties.components,
                                                function (item, thIndex) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: item.id,
                                                      staticClass:
                                                        "design-item-table-td",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("form-drawer-item", {
                                                        staticClass:
                                                          "design-item",
                                                        class: {
                                                          "design-item-actived":
                                                            _vm._activedWidget
                                                              .id === item.id,
                                                        },
                                                        attrs: { widget: item },
                                                        on: {
                                                          copy: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleCopyWidget(
                                                              widget,
                                                              thIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                          remove: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleDeleteWidget(
                                                              widget,
                                                              thIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                          select:
                                                            _vm.handleSelectWidget,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              element.properties.components
                                                .length == 0
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "design-item-table-td",
                                                      staticStyle: {
                                                        height: "80px",
                                                        width: "100%",
                                                        color: "#ccc",
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lang_field_from_left"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          key: "table_copy_" + index,
                                          staticClass: "design-tools-copy",
                                          attrs: { title: _vm.$t("lang_copy") },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleCopyWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          key: "table_del_" + index,
                                          staticClass: "design-tools-delete",
                                          attrs: {
                                            title: _vm.$t("lang_delete"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : element.compType == "table"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      key: "table_" + element.id,
                                      staticClass:
                                        "design-item design-item-table",
                                      class: {
                                        "design-item-actived":
                                          _vm._activedWidget.id === element.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleSelectWidget(element)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "table",
                                        {
                                          staticStyle: {
                                            "border-color": "#ffffff",
                                          },
                                          attrs: {
                                            border: "1",
                                            width: "100%",
                                            height: "100%",
                                            cellspacing: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "tr",
                                            { staticStyle: { height: "20%" } },
                                            _vm._l(
                                              element.properties.components,
                                              function (item) {
                                                return _c(
                                                  "th",
                                                  {
                                                    key: item.id,
                                                    staticStyle: {
                                                      height: "42px",
                                                      "box-sizing":
                                                        "border-box",
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(item.compName)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "draggable",
                                            {
                                              staticClass:
                                                "design-item-dragger design-item-table-dragger",
                                              attrs: {
                                                animation: "100",
                                                tag: "tr",
                                                group: "formDragableGroup",
                                              },
                                              on: {
                                                add: function (e) {
                                                  return _vm.handleAddWidgetToTable(
                                                    e,
                                                    element
                                                  )
                                                },
                                                sort: _vm.handleSortWidget,
                                                remove: _vm.handleRemoveWidget,
                                              },
                                              model: {
                                                value:
                                                  element.properties.components,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    element.properties,
                                                    "components",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "element.properties.components",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                element.properties.components,
                                                function (item, thIndex) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: item.id,
                                                      staticClass:
                                                        "design-item-table-td",
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("form-drawer-item", {
                                                        staticClass:
                                                          "design-item",
                                                        class: {
                                                          "design-item-actived":
                                                            _vm._activedWidget
                                                              .id === item.id,
                                                        },
                                                        attrs: { widget: item },
                                                        on: {
                                                          copy: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleCopyWidget(
                                                              widget,
                                                              thIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                          remove: function (
                                                            widget
                                                          ) {
                                                            return _vm.handleDeleteWidget(
                                                              widget,
                                                              thIndex,
                                                              element.properties
                                                                .components
                                                            )
                                                          },
                                                          select:
                                                            _vm.handleSelectWidget,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              element.properties.components
                                                .length == 0
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "design-item-table-td",
                                                      staticStyle: {
                                                        height: "80px",
                                                        width: "100%",
                                                        color: "#ccc",
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "lang_field_from_left"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          key: "table_copy_" + index,
                                          staticClass: "design-tools-copy",
                                          attrs: { title: _vm.$t("lang_copy") },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleCopyWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          key: "table_del_" + index,
                                          staticClass: "design-tools-delete",
                                          attrs: {
                                            title: _vm.$t("lang_delete"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteWidget(
                                                element,
                                                index,
                                                _vm.formConfig.container
                                                  .components
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c("form-drawer-item", {
                                    key: element.id,
                                    staticClass: "design-item",
                                    class: {
                                      "design-item-actived":
                                        _vm._activedWidget.id === element.id,
                                    },
                                    attrs: { widget: element },
                                    on: {
                                      select: _vm.handleSelectWidget,
                                      copy: function (widget) {
                                        return _vm.handleCopyWidget(
                                          widget,
                                          index,
                                          _vm.formConfig.container.components
                                        )
                                      },
                                      remove: function (widget) {
                                        return _vm.handleDeleteWidget(
                                          widget,
                                          index,
                                          _vm.formConfig.container.components
                                        )
                                      },
                                    },
                                  }),
                                ],
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  !_vm.formConfig.container.components ||
                  !_vm.formConfig.container.components.length
                    ? _c("div", { staticClass: "empty-info" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("lang_czctrhdxzjjxbdsj")) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("form-config", {
            attrs: { visible: _vm.formConfDialogVisible },
            on: {
              "update:visible": function ($event) {
                _vm.formConfDialogVisible = $event
              },
            },
          }),
          _c("form-column-config", {
            attrs: { visible: _vm.columnConfDialogVisible },
            on: {
              "update:visible": function ($event) {
                _vm.columnConfDialogVisible = $event
              },
            },
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.previewPageVisible,
                width: "100%",
                top: "5vh",
                "append-to-body": "",
                title: _vm.$t("lang_preview"),
                "close-on-click-modal": false,
                "custom-class": "preview-form-dialog",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.previewPageVisible = $event
                },
              },
            },
            [
              _vm.previewPageVisible
                ? _c("form-viewer", {
                    ref: "formViewerRef",
                    attrs: { use: "preview" },
                  })
                : _vm._e(),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.previewPageVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_shut_down")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSaveFormData },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.jsonDialogVisible,
                width: "70%;",
                title: "JSON",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jsonDialogVisible = $event
                },
              },
            },
            [
              _c("codemirror", {
                staticStyle: { "text-align": "left" },
                attrs: { options: _vm.codeMirrorOptions },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }