var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "40%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        "custom-class": "column-config-dlg",
        title: _vm.$t("lang_form_column_settings"),
      },
      on: { close: _vm.closeDialog },
    },
    [
      _c(
        "div",
        { staticClass: "column-config-container" },
        [
          _c("div", [_vm._v(_vm._s(_vm.$t("lang_pzbdsjxszbgs_jnxbdxxswbgl")))]),
          _c("div", { staticClass: "column-config-header" }, [
            _c("div", { staticClass: "column-config-header-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("lang_form_item_name")))]),
            ]),
            _c("div", { staticClass: "column-config-header-item" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("lang_is_column")))]),
            ]),
          ]),
          _c("el-tree", {
            ref: "appTreeRef",
            staticClass: "column-config-tree",
            attrs: {
              data: _vm.formWidgetTree,
              "node-key": "compCode",
              "default-expand-all": "",
              "expand-on-click-node": false,
              "highlight-current": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _c("div", { staticClass: "column-config-row" }, [
                      _c("div", { staticClass: "column-config-item" }, [
                        _vm._v(" " + _vm._s(data.label) + " "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "column-config-item" },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: data.groupType == "layout" },
                            on: {
                              change: function (val) {
                                return _vm.changeColumnChecked(val, data)
                              },
                            },
                            model: {
                              value: data.display,
                              callback: function ($$v) {
                                _vm.$set(data, "display", $$v)
                              },
                              expression: "data.display",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.saveColumnConfig()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }