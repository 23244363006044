
























import { Vue, Component, Watch } from 'vue-property-decorator';
import EleDataSourceConfigItem from './ele-data-source-config-item.vue';
import EleDataSourceConfigDialog from './ele-data-source-config-dialog.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import { WidgetDataSourceConfigData } from '@/models/form/WidgetDataSourceModel';
@Component({
  name: 'DataSource',
  components: {
    EleDataSourceConfigItem,
    EleDataSourceConfigDialog
  }
})
export default class extends Vue {
  widgetClone: WidgetModel = SystemUtil.cloneDeep(WidgetData);
  selectDataSourceType: string = ''; // 选中的数据源类型
  showDataSourceDialog: boolean = false;
  dataSourceConfig: any = {};

  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  /**
   * 获取当前组件可以配置的数据源
   */
  get _dataSourceConfigs() {
    let dataSourceStruct = this.$store.getters.dataSourceStruct;
    let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
    let compType = this._activedWidget.compType;
    let dataSourceTypeKeys = widgetConfigMapping.find(item => item.type == compType).dataSourceConfigTypes;
    let dataSourceTypes: any[] = [];
    if (dataSourceTypeKeys) {
      dataSourceTypeKeys.forEach(typeKey => {
        let tmp = {
          type: dataSourceStruct[typeKey].type,
          name: dataSourceStruct[typeKey].name
        };
        dataSourceTypes.push(tmp);
      });
    }
    return dataSourceTypes;
  }

  created() {
    this.initData();
  }

  initData() {
    // console.log(SystemUtil.cloneDeep(this._activedWidget));

    this.dataSourceConfig = SystemUtil.cloneDeep(this._activedWidget).dataSource.dataSourceConfig;
    if (this._activedWidget.dataSource && this._activedWidget.dataSource.dataSourceConfig) {
      this.dataSourceConfig = this._activedWidget.dataSource.dataSourceConfig;
      this.selectDataSourceType = this.dataSourceConfig.type;
    } else {
      this.dataSourceConfig = SystemUtil.cloneDeep(WidgetDataSourceConfigData);
      this.selectDataSourceType = '';
    }
    // console.log('%c[info]', 'color: #ffffff; background: hotPink', this.selectDataSourceType);
  }

  /*
   *@description: 点击数据源下每一项弹出配置数据源弹框
   *@author: gongcaixia
   *@date: 2021-05-13 15:29:05
   *@item: 点击当前项的信息
   */
  openDataSourceConfigDialog(item, e) {
    if (e.target.tagName !== 'INPUT') return; //解决  el-radio设置点击事件会触发两次
    let widgetClone = SystemUtil.cloneDeep(this._activedWidget);
    if (this._activedWidget.dataSource.dataSourceConfig && item.type == this._activedWidget.dataSource.dataSourceConfig.type) {
      widgetClone.dataSource.dataSourceConfig = this._activedWidget.dataSource.dataSourceConfig;
    } else {
      let defaultDataSourceConfig = Object.assign(WidgetDataSourceConfigData, item);
      widgetClone.dataSource.dataSourceConfig = defaultDataSourceConfig;
    }

    this.widgetClone = SystemUtil.cloneDeep(widgetClone);
    this.$nextTick(() => {
      this.showDataSourceDialog = true;
    });
  }

  /**
   * description  配置数据源弹框 确定
   * @param {}
   * @returns {}
   */
  saveDataSourceConfig(widget) {
    let result = SystemUtil.cloneDeep(this._activedWidget);
    result.dataSource = widget.dataSource;
    this.widgetClone = result;

    // this.$store.commit('SET_ACTIVED_WIDGET', result);
    this.$emit('change', this.widgetClone);
    this.$nextTick(() => {
      this.showDataSourceDialog = false;
    });
  }

  closeDataSourceConfigDialog() {
    this.showDataSourceDialog = false;
  }

  @Watch('_activedWidget', { deep: true })
  widgetWatcher(newVal: WidgetModel, oldVal: WidgetModel) {
    if (newVal.id != oldVal.id) {
      this.initData();
    }
  }
}
