var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          staticClass: "datasource-group",
          model: {
            value: _vm.selectDataSourceType,
            callback: function ($$v) {
              _vm.selectDataSourceType = $$v
            },
            expression: "selectDataSourceType",
          },
        },
        _vm._l(_vm._dataSourceConfigs, function (item) {
          return _c(
            "el-radio",
            {
              key: item.type,
              staticClass: "datasource-type",
              attrs: { label: item.type, border: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openDataSourceConfigDialog(item, $event)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.name) + " ")]
          )
        }),
        1
      ),
      _c("ele-data-source-config-dialog", {
        attrs: { visible: _vm.showDataSourceDialog, widget: _vm.widgetClone },
        on: {
          "update:visible": function ($event) {
            _vm.showDataSourceDialog = $event
          },
          "update:widget": function ($event) {
            _vm.widgetClone = $event
          },
          save: _vm.saveDataSourceConfig,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }