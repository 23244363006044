



























































import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import { Component, Vue, Watch, InjectReactive } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import EleDataSourceConfig from './ele-data-source-config.vue';
import EleTrigger from './ele-trigger.vue';
import EleDefaultValueConfig from './ele-default-value-config.vue';
import { getFormConfig, replaceWidget } from '../utils';

@Component({
  name: 'EleConfig',
  components: {
    EleDataSourceConfig,
    EleDefaultValueConfig,
    EleTrigger
  }
})
export default class extends Vue {
  activedWidget: WidgetModel = WidgetData;
  activeIndex: string = 'prop';
  widgetConfig: any = null;
  widgetDataSource: any[] = [];

  @InjectReactive()
  formId: any;
  // 缓存中的当前选中表单项
  get _activedWidget() {
    return this.$store.getters.activeWidget;
  }

  // 默认值配置类型
  get _defaultDataConfigs() {
    return this.$store.getters.defaultDataConfigs;
  }

  // 数据源是否可配置
  get _dataSourceConfigable() {
    if (this._activedWidget.compCode) {
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = this._activedWidget.compType;
      let dataSourceStruct = widgetConfigMapping.find((item) => item.type == compType);
      if (dataSourceStruct && dataSourceStruct.dataSourceConfigTypes) {
        return true;
      }
    }
    return false;
  }

  // 默认值是否可配置
  get _defaultValueConfigable() {
    if (this.activedWidget && this.activedWidget.compCode) {
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let dataSourceStruct = widgetConfigMapping.find(
        (item) => item.type == this.activedWidget.compType
      );
      if (dataSourceStruct && dataSourceStruct.defaultDataConfigTypes) {
        return true;
      }
    }
    return false;
  }

  get _triggerConfigable() {
    if (this.activedWidget && this.activedWidget.compCode) {
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let dataSourceStruct = widgetConfigMapping.find(
        (item) => item.type == this.activedWidget.compType
      );
      if (dataSourceStruct && dataSourceStruct.actionTypes) {
        return true;
      }
    }
    return false;
  }

  created() {
    this.initData();
  }

  initData() {
    this.buildDefaultWidgetConfig();
    this.buildWidgetConfigPath();
  }

  // 构造（补全）默认的组件配置属性
  buildDefaultWidgetConfig() {
    if (this._activedWidget && this._activedWidget.id) {
      this.activedWidget = SystemUtil.cloneDeep(this._activedWidget);
    }
  }

  // 构造表单项属性组件
  buildWidgetConfigPath() {
    let componentName = this.activedWidget.compType;
    if (this.activedWidget.compType == 'inputNumber') {
      componentName = 'input-number';
    } else if (this.activedWidget.compType == 'colorPicker') {
      componentName = 'color-picker';
    } else if (this.activedWidget.compType == 'dialogList') {
      componentName = 'dialog-list';
    }
    this.widgetConfig = () => import(`./elements/${componentName}`);
  }

  /**
   * 改变数据源配置时，获取该数据源对应的数据，为设置默认值的数据来源
   * @param {number} param
   * @returns {number}
   */
  changeDataSourceConfig(widget) {
    this.activedWidget = SystemUtil.cloneDeep(widget);
    this.$nextTick(() => {
      this.changeWidgetConfig(widget);
    });
  }

  /**
   * 修改默认值配置时
   */
  changeDefaultDataConfig(defauiltDataConfig) {
    if (defauiltDataConfig) {
      this.activedWidget.dataSource.defaultDataConfig = SystemUtil.cloneDeep(
        defauiltDataConfig
      );
    } else {
      delete this.activedWidget.dataSource.defaultDataConfig;
    }
    this.$nextTick(() => {
      this.changeWidgetConfig(this.activedWidget);
    });
  }

  /**
   * 修改触发器配置
   * @param {number} param
   * @returns {number}
   */
  changeTrigger(widget) {
    this.changeWidgetConfig(widget);
  }

  /**
   * 组件配置改变时
   */
  changeWidgetConfig(widget) {
    this.activedWidget = SystemUtil.cloneDeep(widget);
    let formConfig = SystemUtil.cloneDeep(getFormConfig(this.formId));
    formConfig.container.components = replaceWidget(
      SystemUtil.cloneDeep(widget),
      formConfig.container.components
    );
    this.$store.commit('SET_ACTIVED_WIDGET', widget);
    this.$store.commit('SET_FORM_CONFIG', formConfig);
  }

  @Watch('_activedWidget', { deep: true })
  onActiveItemChange(newVal: WidgetModel, oldVal: WidgetModel) {
    if (newVal.id != oldVal.id) {
      this.initData();
    }
  }
}
